import {getUniqueId} from "@/services/utils";

export default {
    state: {
        messages: [],
    },
    getters: {
        systemMessages: state => state.messages,
    },
    mutations: {
        ADD_MESSAGE (state, params){
            state.messages.push({
                id: getUniqueId(),
                text: params.text,
                type: params.type,
            });
        },

        /**
         * remove message from array
         * @param {state} state
         * @param {number} index - index of message in array
         */
        REMOVE_MESSAGE (state, index){
            state.messages.splice(index, 1);
        }
    },
    actions: {
        addMessage({commit}, params) {
            commit('ADD_MESSAGE', params);
        },

        /**
         * remove message from array
         * @param {any} commit
         * @param {number} index - index of message in array
         */
        removeMessage({commit}, index) {
            commit('REMOVE_MESSAGE', index)
        }

    }
}