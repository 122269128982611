<template>
  <li class="notif-doc__item">
    <div @click="toNotificationPage" class="notif-doc__link flex s-b d-c">
      <p class="notif-doc__date">
      </p>
      <div class="notif-doc__name">
        <p>{{ notificationMessage }}</p>
        <button type="button" class="notif-doc__close" @click="close"></button>
      </div>
    </div>
  </li>
</template>

<script>
import api from "@/services/api";

export default {
  name: "NotificationItem",
  props: {
    notification: Object,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async readNotification() {
      let result = await api.notification.read(this.notification.id);
      if (result) this.close();
    },
    toNotificationPage() {
      this.$router.push(this.notificationLink);
      this.readNotification();
    },
    processMessage() {
      const statusesEnum = this.$enums.notificationStatuses.process;
      let status = this.notification.data.notification_status;
      let _n = this.notification.data;
      if (status === statusesEnum.created) {
        return this.$t('notification.messages.process.created', {name: _n.name});
      }
      if (status === statusesEnum.updated) {
        return this.$t('notification.messages.process.updated', {name: _n.name});
      }
      if (status === statusesEnum.statusChanged) {
        return this.$t('notification.messages.process.statusChanged', {name: _n.name, status: _n.status});
      }
      if (status === statusesEnum.priorityChanged) {
        return this.$t('notification.messages.process.priorityChanged', {name: _n.name, priority: _n.priority});
      }
      if (status === statusesEnum.toModeration) {
        return this.$t('notification.messages.process.toModeration', {name: _n.name});
      }
      if (status === statusesEnum.archived) {
        return this.$t('notification.messages.process.archived', {name: _n.name});
      }
    },
    estateMessage(status) {
      const statusesEnum = this.$enums.notificationStatuses.estate;
      let _n = this.notification.data;
      if (status === statusesEnum.created) {
        return this.$t('notification.messages.estate.created', {name: _n.name});
      }
      if (status === statusesEnum.updated) {
        return this.$t('notification.messages.estate.updated', {name: _n.name});
      }
    },
    processLink() {
      return {
        name: 'processes-single',
        params: {
          id: this.notification.data.id,
        }
      }
    },
    estateLink() {
      return {
        name: 'real-estate-single',
        params: {
          id: this.notification.data.id,
        }
      }
    }
  },
  computed: {
    notificationMessage() {
      const typesEnum = this.$enums.notificationType;
      if (this.notification.type === typesEnum.process) {
        return this.processMessage();
      }
      if (this.notification.type === typesEnum.estate) {
        return this.estateMessage();
      }
    },
    notificationLink() {
      const typesEnum = this.$enums.notificationType;
      if (this.notification.type === typesEnum.process) {
        return this.processLink();
      }
      if (this.notification.type === typesEnum.estate) {
        return this.estateLink();
      }
    }
  },
}
</script>

<style scoped>

</style>