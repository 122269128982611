<template>
  <header class="header flex">
    <div class="burger" id="burger-button" @click="onClickBurger"></div>
    <div class="search" :class="{'open': isSearchOpen}">
      <div class="flex a-c">
        <input class="search__input" v-model.trim="searchText" @input="searchData" type="text" :placeholder="$t('components.search.title')">
        <button class="search__btn"><i class="icon-arrow-blue-left"></i></button>
        <combo-box v-model="currentSearch" :items="searchScope" class="select-in-header" @change="searchData" static>
          <template #header="{item}">{{ item.title }}</template>
          <template #item="{item}">{{ item.title }}</template>
        </combo-box>
      </div>
    </div>
    <div class="info-header a-c flex">
      <div class="search-btn" @click="openMobileSearch">
      </div>
      <div class="alert" @click.stop>
        <notifications-app/>
      </div>
      <div class="user">
        <router-link :to="{name: 'control-panel'}" class="user__link">
          <img v-if="$store.getters['userPhotoURL']" :src="$store.getters['userPhotoURL']" alt="">
          <img v-else src="@/assets/img/user/default.svg" class="data__img" alt="">
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import NotificationsApp from "@/components/Notifications/NotificationsApp";
import TransitionMove from "@/components/Transitions/TransitionMove";
import ComboBox from "@/components/ComboBox";
import {clickOutside} from "@/directives/clickOutside";
import {debounce} from "@/services/utils";

export default {
  name: "HeaderApp",
  components: {
    TransitionMove,
    NotificationsApp,
    ComboBox,
  },
  data() {
    return {
      isOpenNotification: false,
      isSearchOpen: false,
      searchScope: [
        {
          title: this.$t('components.search.items.estates'),
          entity: 'estates'
        },
        {
          title: this.$t('components.search.items.processes'),
          entity: 'processes'
        },
        {
          title: this.$t('components.search.items.employees'),
          entity: 'employees'
        },
      ],
      currentSearch: {
        title: "Real estate",
        entity: 'estates'
      },
      searchText: '',
    }
  },
  methods: {
    onClickBurger() {
      this.$emit('onClickBurger', '');
    },
    searchData() {
      if (this.$route.name !== 'search' && this.searchText) {
        this.$router.push({
          name: 'search'
        });
      }
      const search = {text: this.searchText, entity: this.currentSearch.entity}
      this.$emit('search', search);
    },
    openMobileSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },
    toggleNotifications() {
      this.isOpenNotification = !this.isOpenNotification;
    },
    closeNotifications() {
      if (this.isOpenNotification) {
        this.isOpenNotification = false;
      }
    }
  },
  directives: {
    clickOutside,
  },
  mounted() {
    this.searchData = debounce(this.searchData, 200);
    this.currentSearch = this.searchScope[0];
  }
}
</script>

<style scoped>
.open {
  display: block !important;
}
</style>