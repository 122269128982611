<template>
  <div class="modal-comment">
    <transition-group name="list">
      <message-item
          class="request-doc"
          v-for="(message, index) in systemMessages"
          :class="messageClass(message.type)"
          :key="message.id"
          :message="message"
          @close="close(index)"
      >
      </message-item>
    </transition-group>
  </div>
</template>

<script>
import TransitionMove from "@/components/Transitions/TransitionMove";
import MessageItem from "@/components/Notifications/MessageItem";
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "MessageApp",
  components: {
    TransitionMove,
    MessageItem
  },
  methods: {
    ...mapActions([
      'addMessage',
      'removeMessage'
    ]),
    close(index) {
      this.removeMessage(index)
    },
    messageClass(type) {
      return type === 'error' ? 'request-wrong' : 'request-good';
    }
  },
  computed: {
    ...mapGetters([
      'systemMessages',
    ]),
  },
  watch: {
    systemMessages: {
      handler: function (newMessages) {
        if (this.systemMessages.length > 6) {
          this.close(0)
        }
      },
      deep: true,
    }

  }
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>