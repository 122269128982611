import http from "@/services/api/http";

export default {
    async process(params) {
        return await http.get(`api/dashboard/processes?${params.queryParams}`);
    },

    async documents(params) {
        return await http.get(`api/dashboard/documents?${params.queryParams}`);
    }
}