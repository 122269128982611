import {createStore}        from "vuex";
import auth                 from "@/store/modules/auth";
import locale               from "@/store/modules/locale";
import systemMessages       from "@/store/modules/systemMessages";
import loadManager          from "@/store/modules/loadManager";

export default createStore({
	modules: {
		auth,
		locale,
		systemMessages,
		loadManager,
	}
});
