<template>
<div class="error__block" v-if="errors?.length > 0">
  <p v-for="(error, index) in errors" :key="errors+index">
    {{error.$message}}
  </p>
</div>
</template>

<script>
export default {
  name: "ErrorBlock",
  props: {
    errors: {
      type: Array,
    }
  }
}
</script>

<style scoped>
.error__block{
  color: var(--red-color);
  font-size: var(--small-font-size);
  margin: 10px 0 0 0;
}
</style>