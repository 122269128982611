import http from "@/services/api/http";

export default {
	async all() {
		return await http.get(`api/employees`)
			.then(function (response) {
				return response.data.employees;
			}).catch(function (error) {
				return null;
			});
	},
	async create(params) {
		const config = {
			headers: {"Content-Type": "multipart/form-data"},
		}
		return await http.post(`api/employees/create?${params.queryParams}`, params.formData, config);
	},
	async delete(id) {
		return await http.delete(`api/employees/${id}`);
	},
	async addData() {
		return await http.get(`api/employees/create`);
	},
	async edit(id) {
		return await http.get(`api/employees/${id}/edit`);
	},
	async update(id, params) {
		const config = {
			headers: {"Content-Type": "multipart/form-data"},
		}
		return await http.post(`api/employees/${id}/edit?${params.queryParams}`, params.formData, config);
	}
}