<template>
  <transition-scale>
    <router-view></router-view>
  </transition-scale>
</template>

<script>
import TransitionScale from "@/components/Transitions/TransitionScale";
export default {
  components: {
    TransitionScale,
  }
}
</script>

<style src="@/assets/css/style.css">
</style>
