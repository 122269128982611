import http from "@/services/api/http";

export default {
	async all(params) {
		return await http.get(`api/estates/types?${params?.queryParams}`)
			.then(function (response) {
				return Promise.resolve(response.data.types);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async edit(id) {
		return await http.get(`api/estates/types/${id}`)
			.then(function (response) {
				return Promise.resolve(response.data.type);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async update(id, params) {
		return await http.put(`api/estates/types/${id}`, params)
			.then(function (response) {
				return Promise.resolve(response);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async create(params) {
		return await http.post('api/estates/types/create', params)
			.then(function (response) {
				return Promise.resolve(response);
			})
			.catch(function (error) {
				return Promise.reject(error);
			});
	},
	// TODO add archive
	async delete(id) {
		return await http.delete(`api/estates/types/${id}`)
			.then(function (response) {
				return Promise.resolve(response);
			})
			.catch(function (error) {
				return Promise.reject(error);
			});
	}
}