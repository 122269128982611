import http from "@/services/api/http";
import {downloadBlobFile} from "@/services/utils";

export default {
    async all(params) {
        return await http.get(`api/processes?${params.queryParams}`);
    },
    async allEmployees() {
        return await http.get('api/processes/employees');
    },
    async search(params) {
        return await http.get(`api/processes?${params.queryParams}`)
            .then(function (response) {
                return Promise.resolve(response.data.processes);
            }).catch(function (error) {
                return Promise.reject(error);
            });
    },
    async show(id) {
        return await http.get(`api/processes/${id}`);
    },
    async archived() {
        return await http.get('api/processes/archived');
    },
    async email() {
        return await http.get('api/processes/email')
            .then(function (response) {
                return Promise.resolve(response.data.data);
            }).catch(function (error) {
                return Promise.reject(error);
            });
    },
    async moderate() {
        return await http.get('api/processes/moderate')
            .then(function (response) {
                return Promise.resolve(response);
            }).catch(function (error) {
                return Promise.reject(error);
            });
    },
    async archive(id) {
        return await http.put(`api/processes/${id}`);
    },
    async priority(id, params) {
        return await http.put(`api/processes/${id}/priority?${params.queryParams}`)
            .then(function (response) {
                return Promise.resolve(response);
            }).catch(function (error) {
                return Promise.reject(error);
            });
    },
    async statuses(id, params) {
        return await http.put(`api/processes/${id}/statuses?${params.queryParams}`);
    },
    async create(params) {
        const config = {
            headers: {"Content-Type": "multipart/form-data"},
        };
        return await http.post(`api/processes/create?${params.queryParams}`, params.formData, config);
    },
    async sendToModerate(processId) {
        await http.put(`api/processes/${processId}/moderate`);
    },
    async add() {
        return await http.get(`api/processes/create`);
    },
    async delete(id) {
        return await http.delete(`api/processes/${id}`);
    },
    async edit(id) {
        return await http.get(`api/processes/${id}/edit`);
    },
    async update(id, params) {
        const config = {
            headers: {"Content-Type": "multipart/form-data"},
        };
        return await http.post(`api/processes/${id}/edit?${params.queryParams.toString()}`, params.formData, config);
    },
    async uploadFiles(id, params) {
        const config = {
            headers: {"Content-Type": "multipart/form-data"},
        }
        return await http.post(`api/processes/${id}/files`, params.formData, config)
            .then(function (response) {
                return Promise.resolve(response.data.files)
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    async addMembers(id, params) {
        return await http.put(`api/processes/${id}/employees?${params.queryParams.toString()}`)
            .then(function (response) {
                return Promise.resolve(response.data.members)
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    async deleteMember(processId, memberId) {
        return await http.delete(`api/processes/${processId}/employees/${memberId}`);
    },
    async manageEmployees(processId, params) {
        return await http.put(`api/processes/${processId}/employees/manage?${params.queryParams}`);
    },
    async deleteFile(processId, fileId) {
        return await http.delete(`api/processes/${processId}/files/${fileId}`);
    },
    async replies(id, params) {
        const config = {
            headers: {"Content-Type": "multipart/form-data"},
        };
        return await http.post(`api/processes/${id}/replies`, params.formData,
            config)
            .then(function (response) {
                return Promise.resolve(response.data.replies)
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    async sendMessage(id, params) {
        return await http.post(`api/processes/${id}/send`, params)
            .then(function (response) {
                return Promise.resolve(response.data.chats)
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    async downloadFile(pid, file) {
        const config = {
            responseType: 'blob'
        }
        return await http.get(`api/processes/${pid}/files/${file.id}`, config)
            .then(response => {
                downloadBlobFile(response.data, file.name);
                return true;
            }).catch(error => {
                return null;
            })
    },
    async downloadReplyFile(pid, file) {
        const config = {
            responseType: 'blob'
        }
        return await http.get(`api/processes/${pid}/replies/${file.id}`, config)
            .then(response => {
                downloadBlobFile(response.data, file.name);
                return true;
            }).catch(error => {
                return null;
            })
    }
}