<template>
  <div class="message__item">
      <p class="request-doc__text">
        {{ message.text }}
      </p>
      <span class="request-doc__close" @click="close"></span>
    <div class="progressbar" ref="progressbar">
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageItem",
  props: {
    message: Object
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    this.$refs.progressbar.addEventListener('webkitAnimationEnd', this.close);
  },
  beforeUnmount() {
    this.$refs.progressbar.removeEventListener('webkitAnimationEnd', this.close);
  }
}
</script>

<style scoped>
.message__item{
  position: relative;
}

.progressbar{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 3px;
  animation: move 5s linear 1;
}



@keyframes move {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>