<template>
  <div
      ref="dropdownMenu"
      class="select"
      @click="switchVisibility"
      :class="{'is-active': true}"
      v-click-outside="close">
    <div class="select__header">
      <span v-if="!multiselect" class="select__current">
        <slot name="header" :item="modelValue"/>
        <span class="select__header-placeholder" v-if="!modelValue && !errors?.$error">{{ placeholder }}</span>
        <error-block :errors="errors?.$errors"/>
      </span>
      <div v-else class="multiselect">
        <div class="multiselect__tags" @click.stop>
          <div v-for="(item, index) in modelValue" class="multiselect__tag flex a-c">
            <slot
                name="header"
                :key="item.id"
                :index="index"
                :item="item">
            </slot>
          </div>
          <input
              class="multiselect__input"
              type="text"
              ref="inputMultiselect"
              :placeholder="placeholder"
              @input="searchItems"
              @focus="activeSearch">
        </div>
      </div>
    </div>
    <div class="select__body" v-if="isActive" @click.stop>
      <div class="select__item" v-if="selectableElements.length <= 0">
        No records...
      </div>
      <div
          v-for="(item, index) in selectableElements"
          :key="index" class="select__item"
          @click="selectItem(item)">
        <slot name="item" :item="item" :index="index"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionExpand from "@/components/Transitions/TransitionExpand";
import {clickOutside} from "@/directives/clickOutside";
import ErrorBlock from "@/components/ErrorBlock";

export default {
  name: "ComboBox",
  props: {
    items: Array,
    modelValue: Object,
    multiselect: Boolean,
    errors: Object,
    placeholder: String,
    static: Boolean,
    disabled: Boolean
  },
  components: {
    ErrorBlock,
    TransitionExpand,
  },
  data() {
    return {
      isActive: false,
      searchedItems: [],
      inputValue: '',
    }
  },
  methods: {
    switchVisibility() {
      if (this.disabled) return
      if (!this.multiselect) {
        this.isActive = !this.isActive;
      } else if (this.isActive === false) {
        this.$refs.inputMultiselect.focus();
      } else {
        this.isActive = false;
      }
    },
    searchItems(e) {
      let inputValue = e.target.value;
      let search = new RegExp(inputValue, 'i');
      this.searchedItems = this.items
          .filter(item => search.test(item.name) &&
              !this.modelValue.map(el => el.id).includes(item.id));
    },
    activeSearch(e) {
      this.searchItems(e);
      this.isActive = true;
    },
    selectItem(item) {
      if (this.multiselect) {
        this.modelValue.push(item);
        this.$emit('update:modelValue', this.modelValue);
        this.$emit('change', item);
        this.isActive = false;
        this.searchedItems = [];
        this.$refs.inputMultiselect.value = '';
      } else {
        if (item !== this.modelValue || this.static) {
          this.$emit('update:modelValue', item);
          this.$emit('change', item);
          this.isActive = false;
        } else {
          this.$emit('update:modelValue', '');
          this.$emit('change', '');
          this.isActive = false;
        }

      }
    },
    close() {
      this.isActive = false;
    }
  },
  computed: {
    selectableElements: function () {
      return this.multiselect ? this.searchedItems : this.items;
    }
  },
  directives: {
    clickOutside,
  }
}
</script>

<style scoped>
.multiselect__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.multiselect__input {
  border: none;
  outline: none;
  background: none;
  display: flex;
  flex: 1 0 auto;
  padding: 0;
  margin: 0 0 0 0;
}

.multiselect__input::placeholder {
  color: var(--light-gray-color);
}
</style>