import axios from "axios";
import {getCookie} from "@/services/utils";
import store from "@/store"

const baseURL = process.env.VUE_APP_API_BASE_URL;
let instance = axios.create({
	baseURL,
	// withCredentials: true
});

// before a request is made start the nprogress
instance.interceptors.request.use(config => {
	store.dispatch('setLoad', false);
	return config;
}, error => {
	store.dispatch('setLoad', true);
	return Promise.reject(error);
})

// before a response is returned stop nprogress
instance.interceptors.response.use(response => {
	store.dispatch('setLoad', true);
	return response;
}, error => {
	store.dispatch('setLoad', true);
	return Promise.reject(error);
});

instance.interceptors.request.use(request => {
	request.headers.common['Accept'] = 'application/json';
	request.headers.common['Content-Type'] = 'application/json';
	let token = window.localStorage.getItem('token');
	if (token) {
		request.headers['Authorization'] = `Bearer ${token}`
	}
	return request;
});

instance.interceptors.response.use(
	async response => {
		const i18n = () => import("@/plugins/i18n-setup")
		const _t = await i18n().then(e => e.default.global.t)
		const responseStatus = response.status;
		if (responseStatus === 201) {
			store.dispatch('addMessage', {text: _t('message.success.added')});
		}
		if (response.status === 200 && response.config.method === 'put') {
			store.dispatch('addMessage', {text: _t('message.success.updated')});
		}
		if (response.status === 204 && response.config.method === 'delete') {
			store.dispatch('addMessage', {text: _t('message.success.deleted')});
		}
		return response;
	},
	async error => {
		const i18n = () => import("@/plugins/i18n-setup")
		const _t = await i18n().then(e => e.default.global.t)
		const errorStatus = error.response.status;
		if (errorStatus === 401) {

			window.localStorage.removeItem('user')
			window.localStorage.removeItem('token')

			window.location.reload();
			// await store.dispatch('logout');
			store.dispatch('addMessage', {text: _t('message.error.credentials'), type: 'error'});
		}
		if (errorStatus === 403) {
			store.dispatch('addMessage', {text: _t('message.error.noAccess'), type: 'error'});
		}
		if (errorStatus === 422) {
			if (error.response.data.errors?.email) {
				store.dispatch('addMessage', {text: _t('message.error.email'), type: 'error'});
			} else {
				store.dispatch('addMessage', {text: _t('message.error.incorrectData'), type: 'error'});
			}
		}
		if (errorStatus === 500) {
			store.dispatch('addMessage', {text: _t('message.error.serverError'), type: 'error'});
		}
		return Promise.reject(error);
	}
);

export default instance;