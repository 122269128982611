import store from '@/store';
import enums from "@/plugins/globalVariable";

const appPermissions = [
	{
		routers: ['employees',  'employer-controller', 'departments'],
		actions: ['add', 'edit', 'delete'],
		status: [],
		permissionId: 1, // "manage departments and employees"
		user: false,
	},
	{
		routers: ['roles'],
		actions: ['add', 'edit', 'delete'],
		status: [],
		permissionId: 2, // "manage roles"
		user: false,
	},
	{
		routers: ['processes', 'processes-single'],
		actions: [],
		status: ['active', 'archive'],
		permissionId: 3, // "access to processes"
		user: false,
	},
	{
		routers: ['processes-controller', 'processes-single', 'processes'],
		actions: ['add', 'edit', 'delete', 'moderate'],
		status: ['add', 'edit'],
		permissionId: 4, // "create/edit/moderate processes as the creator"
		user: true,
	},
	{
		routers: ['processes-controller', 'processes-single', 'processes'],
		actions: ['edit', 'moderate'],
		status: ['edit'],
		permissionId: 5, // "edit any processes"
		user: false,
	},
	{
		routers: ['processes-controller', 'processes-single', 'processes'],
		actions: ['add', 'edit', 'delete', 'moderate'],
		status: ['add', 'edit'],
		permissionId: 6, // "moderate/delete any processes"
		user: false,
	},
	{
		routers: ['real-estates', 'real-estate-single'],
		actions: [],
		status: ['active', 'archive'],
		permissionId: 7, // "access to the real estate"
		user: false,
	},
	{
		routers: ['real-estates-controller', 'real-estate-single', 'real-estates'],
		actions: ['add', 'edit', 'delete'],
		status: [],
		permissionId: 9, // "add/edit/remove real estate"
		user: false,
	},
	{
		routers: ['real-estate-types'],
		actions: ['add', 'edit', 'delete'],
		status: [],
		permissionId: 8, // "add/edit/remove real estate types"
		user: false,
	},
	{
		routers: ['document-types'],
		actions: ['add', 'edit', 'delete'],
		status: [],
		permissionId: 10, // "add/edit/delete document types"
		user: false,
	},
	{
		routers: ['real-estates-document-controller'],
		actions: ['add', 'edit'],
		status: [],
		permissionId: 11, // "add/edit documents"
		user: false,
	},
	{
		routers: ['real-estates-document-controller'],
		actions: ['archive'],
		status: [],
		permissionId: 12, // "update documents"
		user: false,
	},
	{
		routers: ['real-estates-document-controller', 'document-history'],
		actions: ['delete'],
		status: [],
		permissionId: 13, // "update documents"
		user: false,
	},
]

function getRouterPermissions(routerName) {
	return appPermissions.filter(el => el.routers.includes(routerName));
}

function hasAccessToRouter(routerName, status= null){
	const userPermissions = store.getters['rolePermissions'].map(el => el.id);
	let appPermissions = getRouterPermissions(routerName);

	if (appPermissions.length <= 0){
		return true;
	}
	let canAccess = false;
	appPermissions.some(el => {
		canAccess = userPermissions.includes(el.permissionId);
		if (status && canAccess){
			canAccess = el.status.includes(status.toLowerCase());
			return true;
		}
	});
	return canAccess;
}

function hasAccess(params){
	let userPermissions = store.getters['rolePermissions'].map(el => el.id);
	let appPermissions = getRouterPermissions(params.routeName).filter(el => userPermissions.includes(el.permissionId));
	let canAccess = false;
	appPermissions.some(el => {
		canAccess = !!el.actions.includes(params.action);
		if (el.user){
			if (!params.userId) return false;
			canAccess = store.getters['user'].id === params.userId;
			if (canAccess){
				return true;
			}
		}
		if (canAccess) return  true
	});
	return canAccess;
}

export function checkHasScope(scopes) {
	const userScopes = store.getters['rolePermissions'];
	return userScopes.some((scope) =>
		scopes.includes(scope.name)
	);
}

export default {
	hasAccessToRouter,
	hasAccess,
}
