<template>
<slot></slot>
</template>

<script>
export default {
  name: "AccordionApp",
  data() {
    return {
      Accordion: {
        count: 0,
        active: null
      }
    };
  },
  provide() {
    return { Accordion: this.Accordion };
  },
}
</script>

<style scoped>

</style>