<template>
  <ul class="nav">
    <li class="nav__item open-link" @click="clickLink" style="cursor: pointer">
      <a class="icon-control-panel nav__link flex a-c"
                   :class="{'active-link-page': $route.name === 'control-panel' }"
                   @click="$router.push({name: 'control-panel'})">
        {{ $t('sidebar.controlPanel') }}
      </a>
    </li>
    <accordion-app>
      <accordion-item v-for="(item, index) in routerLinks" :key="index">
        <template #title>
          <div class="nav-link__header">
            <span :class="item.iconClass"></span>
            <span class="nav-link__header-title">{{ item.name }}</span>
          </div>
        </template>
        <nav-links @onclick="clickLink" :links="item.routeLinks"/>
      </accordion-item>
    </accordion-app>
  </ul>
</template>

<script>

import NavLinks from "@/components/NavLinks";
import AccordionApp from "@/components/Accordion/AccordionApp";
import AccordionItem from "@/components/Accordion/AccordionItem";
import permission from "@/services/permissions";

export default {
  name: "SidebarLinks",
  components: {
    NavLinks,
    AccordionApp,
    AccordionItem
  },
  methods: {
    clickLink() {
      this.$emit('onclick', '');
    }
  },
  data() {
    return {}
  },
  computed: {
    links() {
      return [
        {
          name: this.$t('sidebar.employees.title'),
          iconClass: 'icon-employ',
          routeLinks: [
            {
              title: this.$t('sidebar.employees.links.employees'),
              routeName: 'employees',
            },
            {
              title: this.$t('sidebar.employees.links.addEmployee'),
              routeName: 'employer-controller',
              params: {
                status: this.$enums.actions.add
              },
            },
            {
              title: this.$t('sidebar.employees.links.department'),
              routeName: 'departments',
            },
            {
              title: this.$t('sidebar.employees.links.role'),
              routeName: 'roles',
            }
          ]
        },
        {
          name: this.$t('sidebar.processes.title'),
          iconClass: 'icon-process',
          routeLinks: [
            {
              title: this.$t('sidebar.processes.links.activeProcesses'),
              routeName: 'processes',
              params: {
                status: this.$enums.status.process.active
              }
            },
            {
              title: this.$t('sidebar.processes.links.addProcess'),
              routeName: 'processes-controller',
              params: {
                status: this.$enums.actions.add
              }
            },
            {
              title: this.$t('sidebar.processes.links.archiveProcesses'),
              routeName: 'processes',
              params: {
                status: this.$enums.status.process.archive
              }
            },
          ]
        },
        {
          name: this.$t('sidebar.estate.title'),
          iconClass: 'icon-build',
          routeLinks: [
            {
              title: this.$t('sidebar.estate.links.allEstate'),
              routeName: 'real-estates',
              params: {
                status: this.$enums.status.estate.active
              }
            },
            {
              title: this.$t('sidebar.estate.links.estateTypes'),
              routeName: 'real-estate-types',
            },
            {
              title: this.$t('sidebar.estate.links.documentTypes'),
              routeName: 'document-types',
            },
            {
              title: this.$t('sidebar.estate.links.archiveEstate'),
              routeName: 'real-estates',
              params: {
                status: this.$enums.status.estate.archive
              }
            },
          ]
        },
      ]
    },
    currentRouteName() {
      return this.$route.name;
    },
    routerLinks() {
      return this.links.filter(el => {
        el.routeLinks = el.routeLinks.filter(router =>
            permission.hasAccessToRouter(router.routeName, router.params?.status));
        return el.routeLinks.length > 0;
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-link__header {
  display: flex;
  justify-content: start;
}

.nav-link__header-title{
  max-width: 190px;
  word-break: break-word;
}

</style>