import http from "@/services/api/http";
import {downloadBlobFile} from "@/services/utils";

export default {
	async create(id, params) {
		return await http.post(`api/estates/${id}/documents?${params.queryParams}`, params.formData, {
			headers: {"Content-Type": "multipart/form-data"},
		});
	},
	async delete(id, did) {
		return await http.delete(`api/estates/${id}/documents/${did}/archived`);
	},
	async add() {
		return await http.get(`api/estates/documents`);
	},
	async edit(id, did) {
		return await http.get(`api/estates/${id}/documents/${did}`);
	},
	async update(id, did, params) {
		return await http.post(`api/estates/${id}/documents/${did}?${params.queryParams}`, params.formData, {
			headers: {"Content-Type": "multipart/form-data"},
		});
	},
	async archive(id, tid) {
		return await http.get(`api/estates/${id}/documents/types/${tid}/archived`);
	},
	async archived(id, did) {
		return await http.put(`api/estates/${id}/documents/${did}/archived`)
			.then(function (response) {
				return Promise.resolve(response);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async downloadFile(id, did, file) {
		const config = {
			responseType: 'blob'
		}
		return await http.get(`api/estates/${id}/documents/${did}/files/${file.id}`, config)
			.then(response => {
				downloadBlobFile(response.data, file.name);
				return true;
			}).catch(error => {
				return null;
			})
	}
}