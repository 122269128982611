export default {
	state: {
		isLoad: false
	},
	getters: {
		isLoad: state => state.isLoad,
	},
	mutations: {
		SET_LOAD (state, load){
			state.isLoad = load;
		}
	},
	actions: {
		setLoad({commit}, load) {
			commit('SET_LOAD', load);
		}
	}
}