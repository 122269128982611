<template>
  <div v-click-outside="closeNotifications">
    <div type="button" class="icon-bell alert__link" @click="toggleNotifications">
      <span class="counter" v-if="messages?.length > 0">{{ messages?.length }}</span>
    </div>
    <transition-move>
      <div class="modal-notifications__wrapp" v-if="isOpen">
        <div class="modal-notifications__container" @click.stop>
          <div class="modal-notifications__info">
            <h2 class="modal-notifications__title">{{ $t('notification.title') }}</h2>
            <transition-move>
              <ul class="notif-doc" v-if="messages.length > 0">
                <transition-group name="list">
                  <notification-item
                      v-for="(item, index) in messages"
                      :notification="item"
                      :key="item.id"
                      @close="removeMessage(item.id, index)"
                  />
                </transition-group>
              </ul>
              <div class="notif-doc__item" v-else><p>{{$t('notification.noRecords')}}</p></div>
            </transition-move>
          </div>
          <div class="modal-notifications__btn flex s-b a-c">
            <router-link @click="closeNotifications" class="doc-settings-btn" :to="{name: 'settings'}"></router-link>
            <button v-if="messages.length > 0" class="doc-delete-btn" @click="deleteAll">{{ $t('notification.buttons.delete') }}</button>
          </div>
        </div>
      </div>
    </transition-move>
  </div>
</template>

<script>
import NotificationItem from "@/components/Notifications/NotificationItem";
import TransitionMove from "@/components/Transitions/TransitionMove";
import api from "@/services/api";
import {Centrifuge} from "centrifuge";
import {clickOutside} from "@/directives/clickOutside";

export default {
  name: "NotificationsApp",
  components: {
    NotificationItem,
    TransitionMove
  },
  directives: {
    clickOutside
  },
  data() {
    return {
      messages: [],
      isOpen: false,
    }
  },
  SOCKET_CLIENT: null,
  methods: {
    async removeMessage(id, index) {
      let result = await api.notification.delete(id);
      if (result) this.messages.splice(index, 1);
    },
    async deleteAll() {
      let result = await api.notification.deleteAll();
      if (result) this.messages = [];
    },
    toggleNotifications() {
      this.isOpen = !this.isOpen;
    },
    closeNotifications() {
      this.isOpen = false;
    },
    async fetchNotifications() {
      let {data} = await api.notification.unRead();
      this.messages = data.data;
    }
  },
  async mounted() {
    await this.fetchNotifications();
    let {data} = await api.websocket.getToken();
    const socketURL = process.env.VUE_APP_SOCKET_URL;
    this.SOCKET_CLIENT = new Centrifuge(socketURL, {token: data.token});

    this.SOCKET_CLIENT.on('connected', function (ctx) {

    });

    const sub = this.SOCKET_CLIENT.newSubscription('notification#' + this.$store.getters['user'].id);

    sub.on('publication', function (ctx) {
      this.messages.push(ctx.data.notification);
    }.bind(this));

    sub.subscribe();

    this.SOCKET_CLIENT.connect();
  },
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>