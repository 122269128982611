<template>
  <transition name="scale" mode="out-in">
    <slot/>
  </transition>
</template>

<script>
export default {
  name: "TransitionScale"
}
</script>

<style scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>