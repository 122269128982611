import http             from "@/services/api/http";
import employee         from "@/services/api/Controllers/employee";
import permission       from "@/services/api/Controllers/permission";
import role             from "@/services/api/Controllers/role";
import department       from "@/services/api/Controllers/department";
import process          from "@/services/api/Controllers/process";
import estate           from "@/services/api/Controllers/estate";
import estateTypes      from "@/services/api/Controllers/estateType";
import documentType     from "@/services/api/Controllers/documentType";
import document         from "@/services/api/Controllers/document"
import notification     from "@/services/api/Controllers/notification";
import settings         from "@/services/api/Controllers/settings";
import dashboard        from "@/services/api/Controllers/dashboard";

export default {
	async getCookie() {
		return await http.get('sanctum/csrf-cookie');
	},

	async login(params) {
		return await http.post('api/login', params);
	},

	async logout() {
		return await http.post('api/logout');
	},

	async downloadFile(fileName) {
		return await http.get(`/uploads/files/${fileName}`);
	},

	async globalSearch(params) {
		return await http.get(`api/search/global?${params.queryParams}`);
	},


	websocket: {
		async getToken() {
			return await http.post('api/connect');
		}
	},

	dashboard,
	settings,
	employee,
	permission,
	role,
	department,
	process,
	estate,
	estateTypes,
	documentType,
	document,
	notification
};