import http from "@/services/api/http";

export default {
	async all(){
		return await http.get('api/permissions')
			.then(function (response) {
				return Promise.resolve(response.data.data);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
}