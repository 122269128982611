const enums = {
	scopes: {
		LOGGED_IN: "LOGGED_IN",
		CAN_MANAGE_USERS: "manage departments and employees",
		CAN_MANAGE_ROLES: "manage roles",
		CAN_READ_PROCESS: "access to processes",
		CAN_MANAGE_PROCESS_AS_CREATOR: "сreate/edit/moderate processes as the creator",
		CAN_EDIT_PROCESSES: "edit any processes",
		CAN_DELETE_AND_MODERATE_PROCESSES: "moderate/delete any processes",
		CAN_READ_REAL_ESTATES: "access to the real estate",
		CAM_MANAGE_REAL_ESTATES: "add/edit/remove real estate",
		CAN_MANAGE_DOCUMENT_TYPES: "add/edit/delete document types",
		CAN_MANAGE_REAL_ESTATE_TYPES: "add/edit/remove real estate types",
		CAN_ADD_AND_EDIT_DOCUMENTS: "add/edit documents",
		CAN_UPDATE_DOCUMENTS: "update documents",
		CAN_DELETE_DOCUMENTS: "delete documents",
	},


	actions: {
		add: 'add',
		edit: 'edit',
		delete: 'delete',
		moderate: 'moderate',
		archive: 'archive'
	},

	status: {
		process: {
			active: 'active',
			archive: 'archive'
		},
		estate: {
			active: 'active',
			archive: 'archive'
		},
		MODERATION: "moderation",
		DONE: "done",
		IN_WORK: "in work"
	},

	notificationType: {
		process: 'App\\Notifications\\ProcessNotification',
		estate: 'App\\Notifications\\RealEstateNotification'
	},

	notificationStatuses: {
		process: {
			created: 'create',
			updated: 'update',
			toModeration: 'to moderation',
			archived: 'to archive',
			statusChanged: 'status changed',
			priorityChanged: 'priority changed',
		},
		estate: {
			created: 'create',
			updated: 'update',
			toModeration: 'to moderation',
			statusChanged: 'status changed',
			priorityChanged: 'priority changed',
			archived: 'to archive',
		}
	}
}
export default enums;

export const globalVariable = {
	install(app, options) {
		app.config.globalProperties.$enums = enums;
	}
}