<template>
  <div class="wrapp active-page">
    <transition-move>
      <sidebar-app
          :class="{'open': isOpenSideBar}"
          @close="closeSideBar"
      />
    </transition-move>
    <section class="main-content dark-mode" >
      <header-app @onClickBurger="toggleSideBar" @search="searched"/>
      <div class="content">
        <div class="content__wrapp">
          <router-view v-slot="{ Component, route }">
            <transition-scale>
              <component :key="route.name + route.params?.status" :is="Component" :search="search"/>
            </transition-scale>
          </router-view>
        </div>
        <footer-app />
      </div>
    </section>
    <message-app>{{ message.text }}</message-app>
  </div>
</template>

<script>
import SidebarApp from "@/components/Layouts/App/Sidebar/SidebarApp";
import HeaderApp from "@/components/Layouts/App/HeaderApp";
import FooterApp from "@/components/Layouts/App/FooterApp";
import TransitionScale from "@/components/Transitions/TransitionScale";
import MessageApp from "@/components/Notifications/MessageApp";
import TransitionMove from "@/components/Transitions/TransitionMove";
import {clickOutside} from "@/directives/clickOutside";
import { computed, provide, reactive } from "vue";

export default {
  name: "WrapperApp",
  components: {
    SidebarApp,
    HeaderApp,
    FooterApp,
    TransitionScale,
    MessageApp,
    TransitionMove,
  },
  setup() {
    const search = reactive({text: ''});

    provide('search', computed(() => search));

    return { search };
  },
  data (){
    return {
      isOpenSideBar: false,
      isOpenNotification: false,
    }
  },
  methods: {
    toggleSideBar(){
      this.isOpenSideBar = !this.isOpenSideBar;
      document.body.classList.toggle("overlay");
    },
    searched(item) {
      this.search.text = item.text;
      this.search.entity = item.entity;
    },

    closeSideBar() {
      this.isOpenSideBar = false;
      document.body.classList.remove("overlay");
    }
  },
  directives: {
    clickOutside,
  }
}
</script>

<style scoped>
</style>