import http from "@/services/api/http";

export default {
	async all() {
		return await http.get('api/departments');
	},
	async create(params) {
		return await http.post('api/departments/create', params);
	},
	async delete(id) {
		return await http.delete(`api/departments/${id}`);
	},
	async edit(id) {
		return await http.get(`api/departments/${id}/edit`)
			.then(function (response) {
				return Promise.resolve(response.data.data);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async update(id, params) {
		return await http.put(`api/departments/${id}/edit`, params);
	}
}