import api from "@/services/api"

export default {
	state: {
		user: window.localStorage.getItem('user') ?
			JSON.parse(window.localStorage.getItem('user')) : null,
		token: window.localStorage.getItem('token') ?
			window.localStorage.getItem('token') : null,
		rolePermissions:
			window.localStorage.getItem('rolePermissions') ?
			JSON.parse(window.localStorage.getItem('rolePermissions')) : [],
		permissions:
			window.localStorage.getItem('permissions') ?
			JSON.parse(window.localStorage.getItem('permissions')) : [],
	},

	getters: {
		user: state => state.user,
		authenticated: state => state.user !== null,
		token: state => state.token,
		rolePermissions: state => state.rolePermissions,
		permissions: state => state.permissions,
		userPhotoURL: state => {
			if (!state.user.photo) return null;
			const photosURL = process.env.VUE_APP_PHOTO_STORAGE;
			return photosURL + state.user.photo;
		}
	},

	mutations: {
		SET_USER(state, user) {
			window.localStorage.setItem('user', JSON.stringify(user));
			state.user = user;
		},
		SET_TOKEN(state, token) {
			window.localStorage.setItem('token', token);
			state.token = token;
		},
		SET_ROLE_PERMISSIONS(state, permissions) {
			window.localStorage.setItem('rolePermissions', JSON.stringify(permissions));
			state.rolePermissions = permissions
		},
		SET_PERMISSIONS(state, permissions) {
			window.localStorage.setItem('permissions', JSON.stringify(permissions));
			state.permissions = permissions;
		}
	},

	actions: {
		async login({commit}, user) {
			// await api.getCookie();
			const response = await api.login(user)
				.then(function (response) {
					return response;
				}).catch(function (error) {
					return error.response;
				});

			if (200 === response.status) {
				commit('SET_USER', response.data.user);
				commit('SET_TOKEN', response.data.token);
				commit('SET_ROLE_PERMISSIONS', response.data.user_permissions);
				return Promise.resolve(response);
			} else {
				window.localStorage.removeItem('rolePermissions');
				window.localStorage.removeItem('user');
				window.localStorage.removeItem('token');
				window.localStorage.removeItem('permissions');
				return Promise.reject(response)
			}
		},
		async logout({commit}) {
			await api.logout();
			window.localStorage.removeItem('user');
			window.localStorage.removeItem('token');
			window.localStorage.removeItem('rolePermissions');
			window.localStorage.removeItem('permissions');
			window.location.reload();
		},
	}
}