export default {
    state: {
        lang: localStorage.locale ? JSON.parse(localStorage.getItem('locale')) : process.env.VUE_APP_I18N_LOCALE,
    },
    getters: {
        lang: state => state.lang,
    },
    mutations: {
        SET_LANG (state, lang){
            state.lang = lang;
        }
    },
    actions: {
        setLanguage({commit}, locale) {
            localStorage.locale = JSON.stringify(locale);
            commit('SET_LANG', locale);
        }
    }
}