<template>
<li v-for="(item, index) in links" :key="item.title" class="nav__item">
  <router-link @click="onclick" class="nav__link"
               :class="{'active-link-page': isCurrentPage(item) }"
               :to="{name: item.routeName, params: item.params}">
    {{item.title}}
    </router-link>
</li>
</template>

<script>
export default {
  name: "NavLinks",
  props: {
    links: Array,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentRouteParams() {
      return this.$route.params;
    },
  },
  methods: {
    isCurrentPage(route){
      return this.currentRouteName === route.routeName && route.params?.status === this.currentRouteParams.status
    },
    onclick() {
      this.$emit('onclick', '');
    }
  }

}
</script>

<style scoped>
.dropdown-menu{
  display: block !important;
}
</style>