<template>
<transition :name="name" mode="out-in">
  <slot></slot>
</transition>
</template>

<script>
export default {
  name: "TransitionMove",
  props: {
    name: {
      type: String,
      default: 'move-right',
    },
  }
}
</script>

<style scoped>


.move-right-enter-active,
.move-right-leave-active {
  transition: all 0.5s ease;
}

.move-right-enter-from,
.move-right-leave-to {
  opacity: 0;
  transform: translateX(30%);
}

.move-up-enter-active,
.move-up-leave-active {
  transition: all 0.5s ease;
}

.move-up-enter-from,
.move-up-leave-to {
  opacity: 0;
  transform: translateY(30%);
}
</style>