export function getCookie(cookieName) {
	let cookie = {};
	document.cookie.split(';').forEach(function (el) {
		let [key, value] = el.split('=');
		cookie[key.trim()] = value;
	})
	return cookie[cookieName];
}

export function lazyLoadView(view) {
	return () => import(`@/views/${view}.vue`)
}

export function getUniqueId() {
	return Date.now();
}

export function buildFormData(data) {
	let formData = new FormData();
	Object.keys(data).forEach(key => {
		formData.append(key, data[key])
	});
	return formData;
}

// https://markus.oberlehner.net/blog/lazy-load-vue-components-when-they-become-visible/
export function lazyLoadComponent({componentFactory, loading, loadingData}) {
	let resolveComponent;
	return () => ({
		// We return a promise to resolve a
		// component eventually.
		component: new Promise((resolve) => {
			resolveComponent = resolve;
		}),
		loading: {
			mounted() {
				// We immediately load the component if
				// `IntersectionObserver` is not supported.
				if (!('IntersectionObserver' in window)) {
					componentFactory().then(resolveComponent);
					return;
				}

				const observer = new IntersectionObserver((entries) => {
					// Use `intersectionRatio` because of Edge 15's
					// lack of support for `isIntersecting`.
					// See: https://github.com/w3c/IntersectionObserver/issues/211
					if (entries[0].intersectionRatio <= 0) return;

					// Cleanup the observer when it's not
					// needed anymore.
					observer.unobserve(this.$el);
					// The `componentFactory()` resolves
					// to the result of a dynamic `import()`
					// which is passed to the `resolveComponent()`
					// function.
					componentFactory().then(resolveComponent);
				});
				// We observe the root `$el` of the
				// mounted loading component to detect
				// when it becomes visible.
				observer.observe(this.$el);
			},
			// Here we render the the component passed
			// to this function via the `loading` parameter.
			render(createElement) {
				return createElement(loading, loadingData);
			},
		},
	});
}

export function throttle(func, ms) {
	let isThrottled = false;
	let savedArgs;
	let savedThis;

	function wrapper() {

		if (isThrottled) {
			savedArgs = arguments;
			savedThis = this;
			return;
		}

		func.apply(this, arguments);

		isThrottled = true;

		setTimeout(function () {
			isThrottled = false;
			if (savedArgs) {
				wrapper.apply(savedThis, savedArgs);
				savedArgs = savedThis = null;
			}

		}, ms);
	}

	return wrapper;
}

export const debounce = (fn, ms) => {
	let timeout;
	return function () {
		const fnCall = () => { fn.apply(this, arguments) }
		clearTimeout(timeout);
		timeout = setTimeout(fnCall, ms)
	};
}

export function downloadBlobFile(data, fileName) {
	const blob = new Blob([data]);
	if (typeof window.navigator.msSaveBlob !== 'undefined') {
		window.navigator.msSaveBlob(blob, fileName);
		return;
	}
	const blobURL = window.URL.createObjectURL(blob);
	const tempLink = document.createElement('a');
	tempLink.style.display = 'none';
	tempLink.href = blobURL;
	tempLink.setAttribute('download', fileName);
	if (typeof tempLink.download === 'undefined') {
		tempLink.setAttribute('target', '_blank');
	}
	document.body.appendChild(tempLink);
	tempLink.click();
	document.body.removeChild(tempLink);
	setTimeout(() => {
		window.URL.revokeObjectURL(blobURL);
	}, 100);
}

export const waitLoading = (func, loadingFlag) => {
	return async function() {
		loadingFlag.value = false;
		const result = await func.apply(this, arguments);
		loadingFlag.value = true;
		return result;
	}
}
