import { createApp }        from 'vue'
import App                  from '@/App.vue'
import router               from '@/router'
import store                from '@/store'
import i18n                 from '@/plugins/i18n-setup';
import {globalVariable}     from "@/plugins/globalVariable";

createApp(App)
	.use(store)
	.use(i18n)
	.use(router)
	.use(globalVariable)
	.mount('#app');
