import http from "@/services/api/http";

export default {
	async unRead() {
		return await http.get('api/notifications/unread');
	},
	async read(id) {
		return await http.put(`api/notifications/${id}`);
	},
	async deleteAll() {
		return await http.post('api/notifications');
	},
	async delete(id) {
		return await http.delete(`api/notifications/${id}`);
	}
}