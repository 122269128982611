import http from "@/services/api/http";

export default {
	async all() {
		return await http.get('api/roles');
	},
	async add() {
		return await http.get('api/roles/create');
	},
	async create(params) {
		return await http.post(`api/roles/create?${params}`);
	},
	async delete(id) {
		return await http.delete(`api/roles/${id}`);
	},
	async edit(id) {
		return await http.get(`api/roles/${id}/edit`);
	},
	async update(id, params) {
		return await http.put(`api/roles/${id}/edit`, params);
	}
}