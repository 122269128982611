import WrapperAuth from "@/components/Layouts/Auth/WrapperAuth";
import WrapperApp from "@/components/Layouts/App/WrapperApp";
import middleware from './middleware';
import {lazyLoadView} from '@/services/utils'
import i18n from "@/plugins/i18n-setup";
import enums from "@/plugins/globalVariable";
const scopes = enums.scopes;

const $t = i18n.global.t;

export default [
	{
		path: '/:pathMatch(.*)*',
		name: 'page-404',
		beforeEnter: middleware.setTitle,
		component: lazyLoadView('EntryGroup/NotFoundView'),
		meta: {
			title: $t('pageTitle.error404')
		}
	},
	{
		path: '/login',
		name: 'auth',
		component: WrapperAuth,
		children: [
			{
				path: '',
				name: 'login',
				component: lazyLoadView('EntryGroup/LoginView'),
				beforeEnter: [middleware.guest, middleware.setTitle],
				meta: {
					title: $t('pageTitle.login')
				}
			},
			{
				path: 'select-lang',
				name: 'select-lang',
				component: lazyLoadView('EntryGroup/SelectLangView'),
				beforeEnter: [middleware.user, middleware.setTitle],
				meta: {
					title: $t('pageTitle.selectLang')
				}
			},
		],
	},
	{
		path: '/',
		component: WrapperApp,
		beforeEnter: middleware.user,
		children: [
			{
				path: '/',
				name: 'control-panel',
				component: lazyLoadView('ControlPanel'),
				beforeEnter: middleware.setTitle,
				meta: {
					title: $t('pageTitle.controlPanel')
				}
			},
			{
				path: 'search',
				name: 'search',
				component: lazyLoadView('SearchView'),
				beforeEnter: middleware.setTitle,
				meta: {
					accessScopes: [scopes.CAN_MANAGE_USERS, scopes.CAN_READ_PROCESS, scopes.CAN_READ_REAL_ESTATES],
					title: $t('pageTitle.search')
				}
			},
			{
				path: 'settings',
				name: 'settings',
				component: lazyLoadView('SettingsView'),
				beforeEnter: middleware.setTitle,
				meta: {
					title: $t('pageTitle.settings')
				}
			},
			{
				path: 'employees',
				name: 'employees',
				component: lazyLoadView('Employees/EmployeesView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_MANAGE_USERS],
					title: $t('pageTitle.employees')
				}
			},
			{
				path: 'departments',
				name: 'departments',
				component: lazyLoadView('Employees/DepartmentsView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_MANAGE_USERS],
					title: $t('pageTitle.departments')
				}
			},
			{
				path: 'roles',
				name: 'roles',
				component: lazyLoadView('Employees/RolesView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_MANAGE_ROLES],
					title: $t('pageTitle.roles')
				}
			},
			{
				path: 'employer/controller/:status/:id?',
				name: 'employer-controller',
				component: lazyLoadView('Employees/EmployeesControllerView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_MANAGE_USERS],
					title: $t('pageTitle.employeeController')
				}
			},
			{
				path: 'processes/:status',
				name: 'processes',
				component: lazyLoadView('Processes/ProcessesView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_PROCESS],
					title: $t('pageTitle.processes')
				}
			},
			{
				path: 'processes/controller/:status/:id?',
				name: 'processes-controller',
				component: lazyLoadView('Processes/ProcessController'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_PROCESS],
					title: $t('pageTitle.processController')
				}
			},
			{
				path: 'processes/single/:id',
				name: 'processes-single',
				component: lazyLoadView('Processes/ProcessSingleView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_PROCESS],
					title: $t('pageTitle.processSingle')
				}
			},
			{
				path: 'real-estate-types',
				name: 'real-estate-types',
				component: lazyLoadView('RealEstate/RealEstateTypesView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_REAL_ESTATES],
					title: $t('pageTitle.realEstateTypes')
				}
			},
			{
				path: 'real-estates/:status',
				name: 'real-estates',
				component: lazyLoadView('RealEstate/RealEstatesView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_REAL_ESTATES],
					title: $t('pageTitle.realEstates')
				}
			},
			{
				path: 'real-estates/controller/:status/:id?',
				name: 'real-estates-controller',
				component: lazyLoadView('RealEstate/RealEstateController'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_REAL_ESTATES],
					title: $t('pageTitle.realEstateController')
				}
			},
			{
				path: 'real-estate-single/:id/real-estates-document/:status/:did?',
				name: 'real-estates-document-controller',
				component: lazyLoadView('RealEstate/DocumentControllerView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_REAL_ESTATES],
					title: $t('pageTitle.documentController')
				}
			},
			{
				path: 'real-estate-single/:id',
				name: 'real-estate-single',
				component: lazyLoadView('RealEstate/RealEstateSingleView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_REAL_ESTATES],
					title: $t('pageTitle.realEstateSingle')
				}
			},
			{
				path: 'real-estate-single/:id/documents-history/:tid',
				name: 'document-history',
				component: lazyLoadView('RealEstate/DocumentsHistoryView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_REAL_ESTATES],
					title: $t('pageTitle.documentHistory')
				}
			},
			{
				path: 'document-types',
				name: 'document-types',
				component: lazyLoadView('RealEstate/DocumentTypesView'),
				beforeEnter: [middleware.hasAccess, middleware.setTitle],
				meta: {
					accessScopes: [scopes.CAN_READ_REAL_ESTATES],
					title: $t('pageTitle.documentsTypes')
				}
			}
		],
	}
]