<template>
  <li class="nav__item open-link">
    <div class="nav__link flex a-c" @click="open">
      <slot name="title">
      </slot>
      <span class="arrow-menu" :class="{'rotate': visible}"></span>
    </div>
    <ul class="dropdown-menu">
      <transition-expand>
        <div  v-show="visible">
          <slot />
        </div>
      </transition-expand>
    </ul>
  </li>
</template>

<script>
import TransitionExpand from "@/components/Transitions/TransitionExpand";
export default {
  name: "AccordionItem",
  inject: ["Accordion"],
  components: {
    TransitionExpand
  },
  data() {
    return {
      index: false
    };
  },
  computed: {
    visible() {
      return this.index === this.Accordion.active;
    }
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
  },
  mounted() {
    this.index = this.Accordion.count++;
  }
}
</script>

<style scoped>
.arrow-menu{
  transition: all 0.2s linear;
}

.rotate{
  transform: rotate(-180deg);
}
.nav__link{
  cursor: pointer;
}
</style>