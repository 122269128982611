import http from "@/services/api/http";

export default {
	async all() {
		return await http.get(`api/documents/types`)
			.then(function (response) {
				return Promise.resolve(response.data.document_types);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async search(params) {
		return await http.get(`api/documents/types/search?name=${params.name}`)
			.then(function (response) {
				return Promise.resolve(response.data.types);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async edit(id) {
		return await http.get(`api/documents/types/${id}`);
	},
	async update(id, params) {
		return await http.put(`api/documents/types/${id}`, params)
			.then(function (response) {
				return Promise.resolve(response);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async create(params) {
		return await http.post(`api/documents/types?${params}`);
	},
	async add() {
		return await http.get('api/documents/types/create');
	},
	async delete(id) {
		return await http.delete(`api/documents/types/${id}`)
			.then(function (response) {
				return Promise.resolve(response);
			})
			.catch(function (error) {
				return Promise.reject(error);
			});
	}
}