import http from "@/services/api/http";

export default {
	async all(params) {
		return await http.get(`api/estates?${params?.queryParams}`)
			.then(function (response) {
				return Promise.resolve(response.data.estates);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async delete(id) {
		return await http.delete(`api/estates/${id}`)
			.then(function (response) {
				return Promise.resolve(response);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async filter(params) {
		return await http.get(`api/estates/filter/tn?${params.queryParams}`);
	},
	async filterData() {
		return await http.get('api/estates/filter');
	},
	async search(params) {
		return await http.get(`api/estates/search?${params.queryParams}`)
			.then(function (response) {
				return Promise.resolve(response.data.data);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async show(id) {
		return await http.get(`api/estates/${id}`);
	},
	async archived() {
		return await http.get('api/estates/archived')
			.then(function (response) {
				return Promise.resolve(response.data.estates);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async archive(id) {
		return await http.put(`api/estates/${id}/archive`);
	},
	async create(params) {
		const config = {
			headers: {'Content-Type': 'multipart/form-data'}
		}
		return await http.post(`api/estates/create?${params.queryParams}`, params.formData, config)
			.then(function (response) {
				return Promise.resolve(response);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async edit(id) {
		return await http.get(`api/estates/${id}/edit`)
			.then(function (response) {
				return Promise.resolve(response.data);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async update(id, params) {
		return await http.post(`api/estates/${id}/edit?${params.queryParams}`, params.formData, {
			headers: {'Content-Type': 'multipart/form-data'}
		})
			.then(function (response) {
				return Promise.resolve(response);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async  contactCreate(id, params) {
		return await http.post(`api/estates/${id}/contacts/create`, params)
			.then(function (response) {
				return Promise.resolve(response.data.contact);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async contactEdit(id, cid) {
		return await http.get(`api/estates/${id}/contacts/${cid}/edit`)
			.then(function (response) {
				return Promise.resolve(response.data);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async contactDelete(id, cid) {
		return await http.delete(`api/estates/${id}/contacts/${cid}`)
			.then(function (response) {
				return Promise.resolve(response.data);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async contactUpdate(id, cid, params) {
		return await http.put(`api/estates/${id}/contacts/${cid}/edit`, params)
			.then(function (response) {
				return Promise.resolve(response.data.contact);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	},
	async manageEmployees(id, params) {
		return await http.put(`api/estates/${id}/manage?${params.queryParams}`,)
			.then(function (response) {
				return Promise.resolve(response.data);
			}).catch(function (error) {
				return Promise.reject(error);
			});
	}
}