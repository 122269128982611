<template>
<div class="wrapp wrapp-entery-group">
  <div class="wrapp-entery-group__bg">
    <img src="@/assets/img/backgraund/main-bg.webp" alt="">
  </div>
  <div class="logo-entry-group">
    <img src="@/assets/img/logo/logo.svg" alt="">
  </div>
  <router-view></router-view>
</div>

</template>

<script>
export default {
  name: "WrapperAuth"
}
</script>

<style scoped>

</style>