<template xmlns="http://www.w3.org/1999/html">
<section class="sidebar" id="side">
  <nav class="navbar">
    <div class="navbar__wrapp flex d-c">
      <div class="nav-info">
        <div class="logo-wrapp flex a-c">
          <div class="close-btn" id="close" @click="close"></div>
          <router-link :to="{name: 'control-panel'}" class="nav-info__logo" href="/"><img src="@/assets/img/logo/logo.svg" alt=""></router-link>
        </div>
        <sidebar-links @onclick="close"/>
      </div>
      <div class="settings">
        <button
            class="icon-settings flex a-c"
            :class="{'active-link-page': $route.name === 'settings' }"
            @click="openSettings">{{$t('sidebar.settings')}}
        </button>
      </div>
    </div>
  </nav>
</section>
</template>

<script>
import SidebarLinks from "@/components/Layouts/App/Sidebar/SidebarLinks";

export default {
  name: "SidebarApp",
  components: {
    SidebarLinks
  },
  methods: {
    close() {
      this.$emit('close', '');
    },
    openSettings() {
      this.close();
      this.$router.push({
        name: 'settings'
      })
    }
  }
}
</script>

<style scoped>

</style>